<template>
  <div class="wrapper">
    <div class="tips">
      <div class="findtime">发现时光</div>
      <img src="../../assets/img/home/icon_add.png" alt="">
    </div>
    <div class="item" v-for="(item, index) in buzzList" :key="index">
      <div class="title">
        <div class="left">
          <img :src="item.avatarTxId | avatarUrl" alt="">
        </div>
        <div class="right">
          <div class="name">布尔诺</div>
          <div class="more">
            <div>MetaID: wi092o</div>
            <div class="time">12月16日 14:04</div>
          </div>
        </div>
      </div>
      <div class="content">
        <img v-for="(img, index) in item.attachments" :src="img | fileUrl" alt="">
        <div>
<!--          村里有个姑娘她叫小微<br/>-->
<!--          她长的漂亮又可爱，一双迷人的大眼睛-->
          {{ item.content }}
        </div>
      </div>
      <div class="operation">
        <div class="tx" @click="viewTX(item.txId)">{{ $t('lang.checkTX') }}</div>
        <div class="comment" @click="comment(item.txId)">
          <img src="../../assets/img/findtime/ico_com.svg" alt="">
          <span>24</span>
        </div>
        <div class="like">
          <img src="../../assets/img/findtime/ico_col.svg" alt="">
          <span>10</span>
        </div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }" class="comments" @close="closeCommentPopup">
      <div class="content">
        全部评论 {{commentList.length}}
      </div>
      <div v-if="commentList && commentList.length" v-for="(item, index) in commentList" :key="index" class="comment_item">
        <img :src="item.metaId | avatarUrl" alt="">
        <div>
          <div>{{item.userName}}</div>
          <div>{{item.content}}</div>
          <div>回复</div>
        </div>
        <img src="../../assets/img/findtime/ico_col.svg" alt="">
      </div>
      <div class="nodata" v-else>
        <img src="../../assets/img/findtime/default_icon_coment.svg" alt="">
        <span class="c_tips">{{ $t('lang.noCommentYet') }}</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {getBuzzCustomizeList, getBuzzInteractiveList} from "api/api";
import {Popup} from "vant";

export default {
  name: "FindTime",
  data() {
    return {
      buzzList: [],
      show: false,
      commentList: []
    }
  },
  components: {
    [Popup.name]: Popup
  },
  created() {
    let params = {
      metaId: "",
      page: "1",
      pageSize: "15",
      protocols: ["SimplePhotoShare", "PayLike", "PayComment"],
      timestamp: 0
    }
    getBuzzCustomizeList(params).then(res => {
      debugger
      this.buzzList = [...this.buzzList, ... res.data.results.items]
    })
  },
  methods: {
    viewTX(txId) {
      location.href = `https://whatsonchain.com/tx/${txId}`
    },
    comment(txId) {
      this.show = true
      let params = {
        buzzTxId: txId,
        metaId: "",
        page: "1",
        pageSize: "500",
        protocols: ["PayLike", "PayComment"],
        timestamp: 0
      }
      getBuzzInteractiveList(params).then(res => {
        this.commentList = res.data.results.items
      })
    },
    closeCommentPopup() {
      this.commentList = []
    }
  },
  filters: {
    fileUrl(tx) {
      if (tx.indexOf("metafile://") != -1) {
        return `${process.env.VUE_APP_AppImgApi}/metafile/${tx.replace("metafile://", "compress/").toLowerCase()}`
      } else {
        return `${process.env.VUE_APP_AppImgApi}/metafile/compress/${tx}`
      }
    },
    avatarUrl(avatarTx){
      if(avatarTx){
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/${avatarTx}`
      }else{
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/undefined`
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    background-color: #F5F7FA;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .tips {
      height: 48px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .findtime {
        font-size: 20px;
        color: #303133;
        font-weight: 600;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
    .item {
      margin-top: 20px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        .left {
          width: 50px;
          height: 50px;
          margin: 15px 10px 15px 20px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .right {
          .name {
            color: #303133;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
          }
          .more {
            display: flex;
            align-items: baseline;
            margin-top: 1px;
            color: #BFC2CC;
            .time {
              margin-left: 8px;
            }
          }
        }
      }
      .content {
        color: #303133;
        font-size: 14px;
        line-height: 24px;
        margin: 0 20px 10px;
        display: flex;
        flex-direction: column;
        img {
          height: 240px;
        }
      }
      .operation {
        display: flex;
        margin: 22px 0 20px 20px;
        .tx {
          margin-right: 141px;
          color: #909399;
          font-size: 12px;
          line-height: 17px;
        }
        .comment {
          margin-right: 41px;
          color: #303133;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          span {
            margin-left: 5px;
          }
        }
        .like {
          color: #F8423B;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          span {
            margin-left: 5px;
          }
        }
      }
    }
    .comments {
      .content {
        padding: 20px 20px 0px;
      }
      .comment_item {
        display: flex;
        padding: 2px 5px;
        > img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        > div {
          margin-left: 12px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 1;
        }
      }
      .nodata {
        display: flex;
        justify-content: center;
        align-items: center;
        .c_tips {
          font-size: 14px;
          font-weight: 400;
          color: #bfc2cc;
        }
      }
    }
  }
</style>
