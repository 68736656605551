var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm._m(0),
      _vm._l(_vm.buzzList, function (item, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "left" }, [
              _c("img", {
                attrs: { src: _vm._f("avatarUrl")(item.avatarTxId), alt: "" },
              }),
            ]),
            _vm._m(1, true),
          ]),
          _c(
            "div",
            { staticClass: "content" },
            [
              _vm._l(item.attachments, function (img, index) {
                return _c("img", {
                  attrs: { src: _vm._f("fileUrl")(img), alt: "" },
                })
              }),
              _c("div", [_vm._v(" " + _vm._s(item.content) + " ")]),
            ],
            2
          ),
          _c("div", { staticClass: "operation" }, [
            _c(
              "div",
              {
                staticClass: "tx",
                on: {
                  click: function ($event) {
                    return _vm.viewTX(item.txId)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.checkTX")))]
            ),
            _c(
              "div",
              {
                staticClass: "comment",
                on: {
                  click: function ($event) {
                    return _vm.comment(item.txId)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../../assets/img/findtime/ico_com.svg"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("24")]),
              ]
            ),
            _vm._m(2, true),
          ]),
        ])
      }),
      _c(
        "van-popup",
        {
          staticClass: "comments",
          style: { height: "30%" },
          attrs: { round: "", position: "bottom" },
          on: { close: _vm.closeCommentPopup },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _vm._v(" 全部评论 " + _vm._s(_vm.commentList.length) + " "),
          ]),
          _vm._l(_vm.commentList, function (item, index) {
            return _vm.commentList && _vm.commentList.length
              ? _c("div", { key: index, staticClass: "comment_item" }, [
                  _c("img", {
                    attrs: { src: _vm._f("avatarUrl")(item.metaId), alt: "" },
                  }),
                  _c("div", [
                    _c("div", [_vm._v(_vm._s(item.userName))]),
                    _c("div", [_vm._v(_vm._s(item.content))]),
                    _c("div", [_vm._v("回复")]),
                  ]),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/img/findtime/ico_col.svg"),
                      alt: "",
                    },
                  }),
                ])
              : _c("div", { staticClass: "nodata" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/img/findtime/default_icon_coment.svg"),
                      alt: "",
                    },
                  }),
                  _c("span", { staticClass: "c_tips" }, [
                    _vm._v(_vm._s(_vm.$t("lang.noCommentYet"))),
                  ]),
                ])
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips" }, [
      _c("div", { staticClass: "findtime" }, [_vm._v("发现时光")]),
      _c("img", {
        attrs: { src: require("../../assets/img/home/icon_add.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "name" }, [_vm._v("布尔诺")]),
      _c("div", { staticClass: "more" }, [
        _c("div", [_vm._v("MetaID: wi092o")]),
        _c("div", { staticClass: "time" }, [_vm._v("12月16日 14:04")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "like" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/img/findtime/ico_col.svg"),
          alt: "",
        },
      }),
      _c("span", [_vm._v("10")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }